/**
 * @generated SignedSource<<e3dcc0ec5ad6ee25580b0a8e402a1dc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenu_NotificationCreatedSubscription$variables = {
  prependIn: ReadonlyArray<string>;
};
export type NotificationMenu_NotificationCreatedSubscription$data = {
  readonly notificationCreated: {
    readonly cursor: string;
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
    };
  };
};
export type NotificationMenu_NotificationCreatedSubscription = {
  response: NotificationMenu_NotificationCreatedSubscription$data;
  variables: NotificationMenu_NotificationCreatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "prependIn"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationMenu_NotificationCreatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationEdge",
        "kind": "LinkedField",
        "name": "notificationCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationRowFragment"
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationMenu_NotificationCreatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationEdge",
        "kind": "LinkedField",
        "name": "notificationCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNotification"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRead",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "friendlyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "comment",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "permalink",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          }
                        ],
                        "type": "Notification",
                        "abstractKey": "__isNotification"
                      }
                    ],
                    "type": "PermalinkNotificationBase",
                    "abstractKey": "__isPermalinkNotificationBase"
                  }
                ],
                "type": "ServiceCallModificationRequestedNotification",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "notificationCreated",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "prependIn"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "39a70d86c5c49a6edd7cd869bbfe9c0f",
    "id": null,
    "metadata": {},
    "name": "NotificationMenu_NotificationCreatedSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationMenu_NotificationCreatedSubscription {\n  notificationCreated {\n    node {\n      __typename\n      ...NotificationRowFragment\n      id\n    }\n    cursor\n  }\n}\n\nfragment NotificationRowFragment on Notification {\n  __isNotification: __typename\n  __typename\n  ...NotificationRow_ModificationRequestedFragment\n}\n\nfragment NotificationRow_BaseFragment on Notification {\n  __isNotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_ModificationRequestedFragment on ServiceCallModificationRequestedNotification {\n  isRead\n  friendlyId\n  comment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotificationBase {\n  __isPermalinkNotificationBase: __typename\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n"
  }
};
})();

(node as any).hash = "06859807577d5f7af744107e67474d1a";

export default node;
