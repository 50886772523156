/**
 * @generated SignedSource<<9d950c8e3e855daa1ba1bc27ad1f095b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as accessoryGroupLabelResolverType } from "../../../__resolvers__/AccessoryGroupLabelResolver";
import { label as accessoryTypeLookupLabelResolverType } from "../../../__resolvers__/AccessoryTypeLabelResolver";
import { label as billingCodeLabelResolverType } from "../../../__resolvers__/BillingCodeLabelResolver";
export type AccessoryLinesFields_AccessoryLineCollectionFragment$data = {
  readonly accessoryLines?: ReadonlyArray<{
    readonly accessoryGroup: {
      readonly branch: {
        readonly name: Record<string, string>;
      } | null | undefined;
      readonly id: string;
      readonly label: string | null | undefined;
    } | null | undefined;
    readonly accessoryOutOfInventory: string | null | undefined;
    readonly accessoryType: {
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    };
    readonly automatic: boolean;
    readonly billable: boolean | null | undefined;
    readonly billingCode: {
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    } | null | undefined;
    readonly defaultQuantity: number | null | undefined;
    readonly id: string;
    readonly isFixedQuantity: boolean;
    readonly isFractionAllowed: boolean;
    readonly quantity: number | null | undefined;
    readonly rate: string | null | undefined;
    readonly requireAccessory: boolean;
    readonly salesRateResult: {
      readonly canEditRate: boolean;
      readonly error: {
        readonly code: string;
        readonly description: string;
      } | null | undefined;
      readonly value: {
        readonly createdAt: string;
        readonly isAnnualContract: boolean;
        readonly isFlexiblePrice: boolean;
        readonly minimumQuantity: number;
        readonly price: string;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_Item_RulesFragment">;
  }>;
  readonly " $fragmentType": "AccessoryLinesFields_AccessoryLineCollectionFragment";
};
export type AccessoryLinesFields_AccessoryLineCollectionFragment$key = {
  readonly " $data"?: AccessoryLinesFields_AccessoryLineCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_AccessoryLineCollectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_AccessoryLineCollectionFragment",
  "selections": [
    {
      "condition": "skipAccessories",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccessoryLine",
          "kind": "LinkedField",
          "name": "accessoryLines",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AccessoryLinesFields_Item_RulesFragment"
            },
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "concreteType": "AccessoryTypeLookup",
                "kind": "LinkedField",
                "name": "accessoryType",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "fragment": {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AccessoryTypeLabelResolver"
                    },
                    "kind": "RelayResolver",
                    "name": "label",
                    "resolverModule": require('./../../../__resolvers__/AccessoryTypeLabelResolver').label,
                    "path": "accessoryLines.accessoryType.label"
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              "action": "THROW",
              "path": "accessoryLines.accessoryType"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requireAccessory",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AccessoryGroup",
              "kind": "LinkedField",
              "name": "accessoryGroup",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AccessoryGroupLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/AccessoryGroupLabelResolver').label,
                  "path": "accessoryLines.accessoryGroup.label"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Branch",
                  "kind": "LinkedField",
                  "name": "branch",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessoryOutOfInventory",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "automatic",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BillingCode",
              "kind": "LinkedField",
              "name": "billingCode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BillingCodeLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/BillingCodeLabelResolver').label,
                  "path": "accessoryLines.billingCode.label"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isFractionAllowed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isFixedQuantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "defaultQuantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesRateResult",
              "kind": "LinkedField",
              "name": "salesRateResult",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canEditRate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesRate",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isAnnualContract",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isFlexiblePrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minimumQuantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesRateError",
                  "kind": "LinkedField",
                  "name": "error",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "CostsInternalBase",
  "abstractKey": null
};
})();

(node as any).hash = "daed6d620acd10b9414f80dabc98e67d";

export default node;
