import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  useFieldBoomConfiguration,
  useFieldBoomLength,
  useFieldCapacity,
  useFieldConfigurationKind,
  useFieldCounterweight,
  useFieldCraneSelectorMode,
  useFieldEquipmentKind,
  useFieldJibLength,
  useFieldMaxWeight,
  useFieldOffsetAngle,
  useFieldRadius,
  useFieldVehicleId,
} from './fields/SaleCraneSelectorFields';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { JobEquipment_useCraneSelectorManualFavoriteFragment$key } from './__generated__/JobEquipment_useCraneSelectorManualFavoriteFragment.graphql';
import { JobEquipment_useResetBoomConfigurationFragment$key } from './__generated__/JobEquipment_useResetBoomConfigurationFragment.graphql';
import { useFieldAdditionalCranesManualCollection } from './AdditionalCranesFields';
import { JobEquipment_useSyncAdditionalCranesManualFragment$key } from './__generated__/JobEquipment_useSyncAdditionalCranesManualFragment.graphql';
import { useEffectEvent } from '../common/utils/effectUtils';
import { JobEquipment_CraneSelector_useSyncAdditionalCranesManualQuery } from './__generated__/JobEquipment_CraneSelector_useSyncAdditionalCranesManualQuery.graphql';
import { SaleCraneSelectorFields_CraneSelectorModeFragment$key } from './fields/__generated__/SaleCraneSelectorFields_CraneSelectorModeFragment.graphql';
import { useOperations, useOperationsError } from '../AppSharedState';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import * as Sentry from '@sentry/react';

export function useCraneSelectorManualFavorite(
  $key: JobEquipment_useCraneSelectorManualFavoriteFragment$key | null | undefined,
  /**
   * TODO: Update fragment type to CraneSelectorInternal to merge in mode checks.
   */
  $craneSelectorModeKey: SaleCraneSelectorFields_CraneSelectorModeFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useCraneSelectorManualFavoriteFragment on ManualConfigurationInternal {
        ...AdditionalCranesFields_AdditionalCranesManualCollectionFragment
        userInput {
          ...SaleCraneSelectorFields_CraneCapacityFragment
          ...SaleCraneSelectorFields_EquipmentKindFragment
          ...SaleCraneSelectorFields_CraneConfigurationKindFragment
          ...SaleCraneSelectorFields_Manual_BoomConfigurationFragment
          ...SaleCraneSelectorFields_VehicleIdFragment
          ...SaleCraneSelectorFields_Manual_BoomLengthFragment
          ...SaleCraneSelectorFields_Manual_JibLengthFragment
          ...SaleCraneSelectorFields_Manual_CounterweightFragment
          ...SaleCraneSelectorFields_Manual_OffsetAngleFragment
          ...SaleCraneSelectorFields_Manual_RadiusFragment
          ...SaleCraneSelectorFields_Manual_MaxWeightFragment
          ...JobEquipment_useResetBoomConfigurationFragment
        }
      }
    `,
    $key,
  );

  // RequiredEquipment
  const { capacity, capacityIsDirty, useValidationCapacity, ...capacityRest } = useFieldCapacity($data?.userInput, disabled, required);
  const { equipmentKind, equipmentKindIsDirty, useValidationEquipmentKind, ...equipmentKindRest } = useFieldEquipmentKind(
    $data?.userInput,
    disabled,
    required,
  );
  const { vehicleIds, vehicleIdsIsDirty, ...vehicleIdsRest } = useFieldVehicleId($data?.userInput, disabled);
  const { configurationKind, configurationKindIsDirty, useValidationConfigurationKind, ...configurationKindRest } =
    useFieldConfigurationKind($data?.userInput, disabled, required);

  // Configuration
  const hasSelectedRequiredFields = !!capacity && !!equipmentKind && !!configurationKind;
  const { boomConfiguration, boomConfigurationIsDirty, useValidationBoomConfiguration, renderBoomConfiguration, ...boomConfigurationRest } =
    useFieldBoomConfiguration($data?.userInput, disabled || !hasSelectedRequiredFields, required);
  const { boomLength, boomLengthIsDirty, useValidationBoomLength, ...boomLengthRest } = useFieldBoomLength(
    $data?.userInput,
    disabled || !hasSelectedRequiredFields,
    required,
  );

  useResetBoomConfiguration($data?.userInput, disabled, required);

  const { jibLength, jibLengthIsDirty, ...jibLengthRest } = useFieldJibLength($data?.userInput, disabled, required);
  const { counterweight, counterweightIsDirty, ...counterweightRest } = useFieldCounterweight($data?.userInput, disabled);
  const { offsetAngle, offsetAngleIsDirty, ...offSetAngleRest } = useFieldOffsetAngle($data?.userInput, disabled);
  const { radius, radiusIsDirty, useValidationRadius, ...radiusRest } = useFieldRadius($data?.userInput, disabled, required);
  const { maxWeight, maxWeightIsDirty, useValidationMaxWeight, ...maxWeightRest } = useFieldMaxWeight($data?.userInput, disabled, required);

  const { craneSelectorMode } = useFieldCraneSelectorMode($craneSelectorModeKey, disabled);
  const isManualMode = craneSelectorMode === 'manual';

  // Manual fields validation
  useValidationCapacity((val) => (required ? isManualMode && !!val : true), [required, isManualMode], 'transferable,submittable:required');
  useValidationEquipmentKind(
    (val) => (required ? isManualMode && !!val : true),
    [required, isManualMode],
    'transferable,submittable:required',
  );
  useValidationConfigurationKind(
    (val) => (required ? isManualMode && !!val : true),
    [required, isManualMode],
    'transferable,submittable:required',
  );
  useValidationBoomConfiguration(
    (val) => (required ? isManualMode && !!val : true),
    [required, isManualMode],
    'transferable,submittable:required',
  );
  useValidationBoomLength(
    (val) => (required ? isManualMode && !!val : true),
    [required, isManualMode],
    'transferable,submittable:required',
  );
  useValidationRadius((val) => (required ? isManualMode && !!val : true), [required, isManualMode], 'transferable,submittable:required');
  useValidationMaxWeight((val) => (required ? isManualMode && !!val : true), [required, isManualMode], 'transferable,submittable:required');

  const { additionalCranesManual, additionalCranesManualAreDirty } = useFieldAdditionalCranesManualCollection($data);

  const manualFavorite = useMemo(
    () =>
      !!capacity && !!equipmentKind && !!configurationKind && !!boomConfiguration
        ? {
            capacity,
            equipmentKind,
            vehicleId: vehicleIds?.[0] ?? null,
            configurationKind,
            boomConfiguration,
            boomLength,
            jibLength,
            counterweight,
            offsetAngle,
            radius,
            maxWeight,
            additionalCranes: additionalCranesManual.filter((v) => !v.$removed),
          }
        : null,
    [
      additionalCranesManual,
      boomConfiguration,
      boomLength,
      capacity,
      configurationKind,
      counterweight,
      equipmentKind,
      jibLength,
      maxWeight,
      offsetAngle,
      radius,
      vehicleIds,
    ],
  );
  const manualFavoriteIsDirty = useMemo(
    () =>
      capacityIsDirty ||
      equipmentKindIsDirty ||
      configurationKindIsDirty ||
      boomConfigurationIsDirty ||
      vehicleIdsIsDirty ||
      boomLengthIsDirty ||
      jibLengthIsDirty ||
      counterweightIsDirty ||
      offsetAngleIsDirty ||
      radiusIsDirty ||
      maxWeightIsDirty ||
      additionalCranesManualAreDirty,
    [
      capacityIsDirty,
      equipmentKindIsDirty,
      configurationKindIsDirty,
      boomConfigurationIsDirty,
      vehicleIdsIsDirty,
      boomLengthIsDirty,
      jibLengthIsDirty,
      counterweightIsDirty,
      offsetAngleIsDirty,
      radiusIsDirty,
      maxWeightIsDirty,
      additionalCranesManualAreDirty,
    ],
  );

  const renderManualBoomConfiguration = useCallback(
    () => renderBoomConfiguration(capacity?.capacity ?? 0, configurationKind?.code ?? 0, equipmentKind?.code ?? 0),
    [capacity?.capacity, configurationKind?.code, equipmentKind?.code, renderBoomConfiguration],
  );

  // TODO: Fix tech debt by adding specialised render function for whole sections here because we'll never really want
  //  to display capacity/equipmentKind/vehicleIds/configurationKind by themselves...
  //  and same thing for the Jib/Maxweight/offsetangle/radius/etc

  return {
    capacity,
    capacityIsDirty,
    ...capacityRest,
    equipmentKind,
    equipmentKindIsDirty,
    ...equipmentKindRest,
    vehicleIds,
    vehicleIdsIsDirty,
    ...vehicleIdsRest,
    configurationKind,
    configurationKindIsDirty,
    ...configurationKindRest,
    boomConfiguration,
    boomConfigurationIsDirty,
    ...boomConfigurationRest,
    boomLength,
    ...boomLengthRest,
    jibLength,
    ...jibLengthRest,
    counterweight,
    ...counterweightRest,
    offsetAngle,
    ...offSetAngleRest,
    radius,
    ...radiusRest,
    maxWeight,
    ...maxWeightRest,
    manualFavorite,
    manualFavoriteIsDirty,
    additionalCranesManualAreDirty,
    renderManualBoomConfiguration,
  };
}

function useResetBoomConfiguration(
  $key: JobEquipment_useResetBoomConfigurationFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useResetBoomConfigurationFragment on ManualConfigurationInfo {
        ...SaleCraneSelectorFields_CraneCapacityFragment
        ...SaleCraneSelectorFields_EquipmentKindFragment
        ...SaleCraneSelectorFields_CraneConfigurationKindFragment
        ...SaleCraneSelectorFields_Manual_BoomConfigurationFragment
      }
    `,
    $key,
  );

  const { setBoomConfiguration } = useFieldBoomConfiguration($data, disabled, required);
  const { capacity } = useFieldCapacity($data, disabled, required);
  const { equipmentKind } = useFieldEquipmentKind($data, disabled, required);
  const { configurationKind } = useFieldConfigurationKind($data, disabled, required);

  const capacityRef = useRef<number | null>();
  const equipmentKindRef = useRef<number | null>();
  const configurationKindRef = useRef<number | null>();

  useEffect(() => {
    if (!capacityRef.current) {
      capacityRef.current = capacity?.capacity;
    } else if (capacityRef.current !== capacity?.capacity) {
      capacityRef.current = capacity?.capacity;
      setBoomConfiguration(null);
    }

    if (!equipmentKindRef.current) {
      equipmentKindRef.current = equipmentKind?.code;
    } else if (equipmentKindRef.current !== equipmentKind?.code) {
      equipmentKindRef.current = equipmentKind?.code;
      setBoomConfiguration(null);
    }

    if (!configurationKindRef.current) {
      configurationKindRef.current = configurationKind?.code;
    } else if (configurationKindRef.current !== configurationKind?.code) {
      configurationKindRef.current = configurationKind?.code;
      setBoomConfiguration(null);
    }
  }, [capacity, equipmentKind, configurationKind, setBoomConfiguration]);
}

export const SYNC_ADDITIONAL_CRANES_MANUAL_OP_KEY = 'useSyncAdditionalCranesManual';
export function useSyncAdditionalCranesManual(
  $key: JobEquipment_useSyncAdditionalCranesManualFragment$key | null | undefined,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useSyncAdditionalCranesManualFragment on CraneSelectorInternal {
        ...SaleCraneSelectorFields_CraneSelectorModeFragment
        manualConfiguration {
          ...AdditionalCranesFields_AdditionalCranesManualCollectionFragment
          userInput {
            ...SaleCraneSelectorFields_Manual_BoomConfigurationFragment
          }
        }
      }
    `,
    $key,
  );

  const { boomConfiguration, boomConfigurationIsDirty } = useFieldBoomConfiguration($data?.manualConfiguration.userInput, false, required);
  const { appendAdditionalCraneManual, clearAdditionalCranesManual } = useFieldAdditionalCranesManualCollection($data?.manualConfiguration);
  const { craneSelectorMode } = useFieldCraneSelectorMode($data, required);
  const environment = useRelayEnvironment();

  const { startOperation, endOperation } = useOperations(SYNC_ADDITIONAL_CRANES_MANUAL_OP_KEY);
  const { resetError, setError } = useOperationsError(SYNC_ADDITIONAL_CRANES_MANUAL_OP_KEY);
  const [_, setSubscription] = useCancellableSubscription();

  const refreshAdditionalCranes = useEffectEvent((id: string) => {
    startOperation();
    resetError();

    setSubscription(
      fetchQuery<JobEquipment_CraneSelector_useSyncAdditionalCranesManualQuery>(
        environment,
        graphql`
          query JobEquipment_CraneSelector_useSyncAdditionalCranesManualQuery($id: ID!) {
            node(id: $id) @required(action: THROW) {
              __typename
              ... on BoomConfiguration {
                id
                additionals {
                  additionalBoomConfiguration @required(action: THROW) {
                    capacity
                    craneConfigurationId
                    equipmentKind @required(action: THROW) {
                      id
                      code
                      label
                    }
                    configurationKind @required(action: THROW) {
                      id
                      code
                      label
                    }
                    id
                    label
                  }
                }
              }
            }
          }
        `,
        { id: id },
      ).subscribe({
        error: (error: Error) => {
          Sentry.captureException(error);
          endOperation();
          setError(error);
        },
        next: (value) => {
          if (value.node.__typename !== 'BoomConfiguration') {
            endOperation();
            throw new Error(`Invalid node type, expected BoomConfiguration but got ${value.node.__typename}`);
          }

          for (const { additionalBoomConfiguration } of value.node.additionals) {
            appendAdditionalCraneManual({
              id: 'new',
              capacity: {
                capacity: additionalBoomConfiguration.capacity,
                label: `${additionalBoomConfiguration.capacity}`,
              },
              equipmentKind: additionalBoomConfiguration.equipmentKind,
              configurationKind: additionalBoomConfiguration.configurationKind,
              boomConfiguration: {
                id: additionalBoomConfiguration.id,
                craneConfigurationId: additionalBoomConfiguration.craneConfigurationId,
                label: additionalBoomConfiguration.label,
              },
            });
          }
          endOperation();
        },
        unsubscribe: () => {
          endOperation();
          resetError();
        },
      }),
    );
  });

  const clearAdditionalCranes = useEffectEvent(() => clearAdditionalCranesManual());

  useEffect(() => {
    if (!boomConfigurationIsDirty || craneSelectorMode !== 'manual') {
      return;
    }

    clearAdditionalCranes();
    if (boomConfiguration) {
      refreshAdditionalCranes(boomConfiguration.id);
    }
  }, [boomConfiguration, boomConfigurationIsDirty, clearAdditionalCranes, craneSelectorMode, refreshAdditionalCranes]);
}
