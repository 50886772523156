const typeMap = new Map([
  [1, 'Accessory'],
  [2, 'AccessoryLine'],
  [3, 'AccessoryTypeLookup'],
  [4, 'Addendum'],
  [5, 'AddendumTemplate'],
  [6, 'Attachment'],
  [7, 'AttachmentBlobMetadata'],
  [8, 'BillingCode'],
  [9, 'BoomCapacity'],
  [10, 'BoomChart'],
  [11, 'BoomConfiguration'],
  [12, 'Branch'],
  [13, 'Client'],
  [14, 'Competitor'],
  [15, 'ConfigurationKindLookup'],
  [16, 'CostLine'],
  [17, 'CraneChart'],
  [18, 'CraneChartBlobMetadata'],
  [19, 'CraneConfiguration'],
  [20, 'Employee'],
  [21, 'EmployeeAuthor'],
  [22, 'EquipmentKindLookup'],
  [23, 'Job'],
  [24, 'JobStageBaseJobRevision'],
  [25, 'LifeCycleBranch'],
  [26, 'Lift'],
  [27, 'NatureOfWorkLookup'],
  [28, 'PlaceToken'],
  [29, 'Quote'],
  [30, 'QuoteJobRevision'],
  [31, 'Representative'],
  [32, 'Requirement'],
  [33, 'ServiceCall'],
  [34, 'ServiceCallJobRevision'],
  [35, 'WorkForceTypeLookup'],
  [36, 'WorkPlanning'],
  [37, 'WorkPlanningJobRevision'],
  [38, 'Worksite'],
  [39, 'Breaker'],
  [40, 'BillingCodeRule'],
  [41, 'PermalinkNotification'],
  [42, 'AccessoryGroup'],
  [43, 'BoomConfigurationAdditional'],
  [44, 'AdditionalConfigurationInfo'],
  [45, 'RateStrategy'],
  [46, 'CraneConfigurationFlatbed'],
  [47, 'RateStrategyLookup'],
  [48, 'DateInfo'],
  [50, 'TransportSegment'],
  [51, 'CalculatorLoadingUnloadingEntry'],
  [52, 'BoomConfigurationWorkForce'],
  [53, 'TransportKindLookup'],
  [54, 'BoomConfigurationTransport'],
  [55, 'EscortKindLookup'],
  [56, 'FlatbedKind'],
  [57, 'PermitKindLookup'],
  [58, 'AccessoryRule'],
  [59, 'CraneConfigurationEscort'],
  [60, 'NotificationBase'],
  [61, 'CraneConfigurationPermit'],
  [62, 'ServiceCallModificationRequestedNotification'],
]);

function base64ToByteArray(encoded: string) {
  return [...atob(encoded)].map((c) => c.charCodeAt(0));
}

function bytesToHex(bytes: number[]) {
  return bytes.map((x) => x.toString(16).padStart(2, '0')).join('');
}

function displayBytes(bytes: number[], start: number, length?: number) {
  return bytesToHex(bytes.slice(start, length));
}

function displayReversedBytes(bytes: number[], start: number, length?: number) {
  return bytesToHex(bytes.slice(start, length).reverse());
}

function displayGuid(bytes: number[]) {
  return `${displayReversedBytes(bytes, 0, 4)}-${displayReversedBytes(bytes, 4, 6)}-${displayReversedBytes(bytes, 6, 8)}-${displayBytes(bytes, 8, 10)}-${displayBytes(bytes, 10)}`;
}

export function deserializeID(id: string): object {
  const unescaped = id.replace(/-/g, '+').replace(/_/g, '/');
  const bytes = base64ToByteArray(unescaped);
  if (bytes[0] !== 1) {
    return { message: `Unexpected ID format. Decoded content: "${atob(unescaped)}"` };
  }

  const type = typeMap.has(bytes[1])
    ? `${typeMap.get(bytes[1])} (${bytes[1]})`
    : `Type ${bytes[1]} was not found. Please update type map in idDeserializer.`;

  return { type: type, guid: displayGuid(bytes.slice(2)) };
}

declare global {
  interface Window {
    /**
     * @deprecated for debugging only
     */
    WeLift: {
      deserializeID: (id: string) => object;
    };
  }
}

window.WeLift = { deserializeID };
