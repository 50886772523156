import { createFieldKey, createFormContext, SetValueFn, useField } from '../../common/utils/forms';
import { ForwardCostBillingCodeAutocompleteProps } from '../../common/components/CostBillingCodeAutocomplete';
import { DataID, useLazyLoadQuery } from 'react-relay';
import { SelectPicker } from '../../common/components/SelectPicker';
import { ForwardWorkForceTypeAutocompleteProps, WorkForceTypeAutocomplete } from '../../common/components/WorkForceTypeAutocomplete';
import graphql from 'babel-plugin-relay/macro';
import { Suspense } from 'react';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useTranslation } from 'react-i18next';
import { BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery } from './__generated__/BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery.graphql';

export type BillingCodeRulesAutomaticRow = {
  id: DataID;
  billingCode: AutomaticBillingCode | undefined;
  workForceType: AutomaticBillingCodeWorkForceType | undefined | null;
};
export const billingCodeRuleAutomaticSubFormContext = createFormContext<{ sync: BillingCodeRulesAutomaticRow }>();

type AutomaticBillingCodeId = DataID;
const fieldAutomaticBillingCodeIdKey = createFieldKey<AutomaticBillingCodeId>();

export function useFieldAutomaticBillingCodeId(initialValue: AutomaticBillingCodeId) {
  const [automaticBillingCodeId, setAutomaticBillingCodeId] = useField(
    billingCodeRuleAutomaticSubFormContext,
    fieldAutomaticBillingCodeIdKey,
    initialValue,
  );

  // Note: I Skipped the mappers since there's only 2 fields and I set the value on change

  return { automaticBillingCodeId, setAutomaticBillingCodeId };
}

export type AutomaticBillingCode = ForwardCostBillingCodeAutocompleteProps<false, true>['value'];
const fieldAutomaticBillingCodeKey = createFieldKey<AutomaticBillingCode>();

export function useFieldAutomaticBillingCode(initialValue: AutomaticBillingCode) {
  const [automaticBillingCode, setAutomaticBillingCode] = useField(
    billingCodeRuleAutomaticSubFormContext,
    fieldAutomaticBillingCodeKey,
    initialValue,
  );

  // Note: I Skipped the mappers since there's only 2 fields and I set the value on change

  return { automaticBillingCode, setAutomaticBillingCode };
}

export function AutomaticBillingCodeInput({
  billingCodesVisible,
  value,
  setValue,
  onChange,
}: {
  billingCodesVisible: ForwardCostBillingCodeAutocompleteProps<true>['value'];
  value: AutomaticBillingCode;
  setValue: SetValueFn<AutomaticBillingCode>;
  onChange?: (value: AutomaticBillingCode) => void;
}) {
  const { t } = useAmbientTranslation();

  return (
    <SelectPicker
      value={value}
      disableClearable
      options={billingCodesVisible ?? []}
      onChange={(_, newValue) => {
        setValue(newValue);
        onChange?.(newValue);
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionKey={(o) => o.id}
      textFieldProps={() => ({
        placeholder: t('button.select', { ns: 'common' }),
        className: 'borderless',
      })}
    />
  );
}

export type AutomaticBillingCodeWorkForceType = ForwardWorkForceTypeAutocompleteProps['value'];
const fieldAutomaticBillingCodeWorkForceTypeKey = createFieldKey<AutomaticBillingCodeWorkForceType>();

export function useAutomaticBillingCodeWorkForceType(initialValue: AutomaticBillingCodeWorkForceType) {
  const [automaticBillingCodeWorkForceType, setAutomaticBillingCodeWorkForceType] = useField(
    billingCodeRuleAutomaticSubFormContext,
    fieldAutomaticBillingCodeWorkForceTypeKey,
    initialValue ?? null,
  );

  // Note: I Skipped the mappers since there's only 2 fields and I set the value on change

  return {
    automaticBillingCodeWorkForceType,
    setAutomaticBillingCodeWorkForceType,
  };
}

export function AutomaticBillingCodeWorkForceTypeInput({
  billingCodeId,
  ...rest
}: {
  billingCodeId: DataID | null | undefined;
  value: AutomaticBillingCodeWorkForceType;
  setValue: SetValueFn<AutomaticBillingCodeWorkForceType>;
  onChange?: (value: AutomaticBillingCodeWorkForceType) => void;
}) {
  if (billingCodeId) {
    return (
      <Suspense fallback={<AutomaticBillingCodeWorkForceTypeInput_Content {...rest} disabled={true} loading={true} />}>
        <AutomaticBillingCodeWorkForceTypeInput_Queried {...rest} billingCodeId={billingCodeId} />
      </Suspense>
    );
  }

  return <AutomaticBillingCodeWorkForceTypeInput_Content {...rest} disabled={true} />;
}

function AutomaticBillingCodeWorkForceTypeInput_Queried({
  billingCodeId,
  ...rest
}: {
  billingCodeId: DataID;
  value: AutomaticBillingCodeWorkForceType;
  setValue: SetValueFn<AutomaticBillingCodeWorkForceType>;
  onChange?: (value: AutomaticBillingCodeWorkForceType) => void;
}) {
  const $data = useLazyLoadQuery<BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery>(
    graphql`
      query BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery($billingCodeId: ID!) {
        node(id: $billingCodeId) {
          ... on BillingCode {
            billingCodeCategory
          }
        }
      }
    `,
    { billingCodeId },
  );

  return <AutomaticBillingCodeWorkForceTypeInput_Content {...rest} disabled={$data.node?.billingCodeCategory !== 'labor'} />;
}

function AutomaticBillingCodeWorkForceTypeInput_Content({
  value,
  setValue,
  disabled,
  loading = false,
  onChange,
}: {
  value: AutomaticBillingCodeWorkForceType;
  setValue: SetValueFn<AutomaticBillingCodeWorkForceType>;
  disabled: boolean;
  loading?: boolean;
  onChange?: (value: AutomaticBillingCodeWorkForceType) => void;
}) {
  const { t } = useTranslation('common');

  return (
    <WorkForceTypeAutocomplete
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
        onChange?.(newValue);
      }}
      textFieldProps={() => ({ className: 'borderless', placeholder: !disabled ? t('button.select') : undefined })}
      disabled={disabled || loading}
    />
  );
}
