const app = {
  title: 'Guay Ventes',
  version: 'Version',
  changeLanguage: 'English',
  reloading: 'L’application redémarre…',
};

const auth = {
  logout: 'Se déconnecter',
  redirectToConnectionPage: 'Vous serez redirigé vers la page de connection.',
  unauthorizedLogin: 'Connexion non autorisée',
  unauthorizedLoginMessage: 'Vous n’avez pas accès à l’application avec cette adresse courriel',
};

const logoutModal = {
  title: 'Confirmation',
  confirmationText: 'Êtes-vous certain de vouloir vous déconnecter\u00a0?',
  confirmButton: 'Déconnecter',
};

const sidebar = {
  billingCodeRules: 'Règles de facturation',
  clients: 'Clients',
  configuration: 'Configuration',
  crm: 'CRM',
  dashboard: 'Tableau de bord',
  workPlannings: 'Planif. des travaux',
  opportunities: 'Opportunités',
  quotes: 'Soumissions',
  representatives: 'Représentants',
  sales: 'Ventes',
  serviceCalls: 'Appels de service',
  worksites: 'Chantiers',
};

const forceRefresh = {
  refresh: 'Rafraîchir',
  version: 'Votre version de l’application n’est plus à jour, svp rafraîchir pour obtenir la version la plus récente',
};

export const layout = {
  app,
  auth,
  logoutModal,
  sidebar,
  forceRefresh,
  notification: {
    title: 'Notifications',
    markAllRead: 'Marquer toutes lues',
    empty: 'Aucune notification',
    modificationRequestedNotification: 'Demande de modification de l’appel de service {{ friendlyId }}',
  },
};
