import {
  createFieldKey,
  createFormContext,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldIsDirty,
  useFieldMapper,
} from '../../common/utils/forms';
import { AccessoryTypeAutocomplete, ForwardAccessoryTypeAutocompleteProps } from '../../common/components/AccessoryTypeAutocomplete';
import { flagDirty } from '../../common/utils/patchable';
import { ReactNode, useCallback } from 'react';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { DataID, useFragment } from 'react-relay';
import { Checkbox, FormControlLabel, FormControlLabelProps, TextField, TextFieldProps } from '@mui/material';
import {
  AccessoryBillingCodeAutocomplete,
  ForwardAccessoryBillingCodeAutocompleteProps,
} from '../../common/components/AccessoryBillingCodeAutocomplete';
import { NumberInput, NumberInputProps } from '../../common/components/NumberInput';
import { Price, PriceInput, PriceInputProps } from '../../common/components/PriceInput';
import { AccessoryLine } from './AccessoryLinesFields';
import graphql from 'babel-plugin-relay/macro';
import { useSuggestions } from '../useSuggestions';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key } from './__generated__/AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment.graphql';
import { AccessoryGroupAutocomplete, ForwardAccessoryGroupAutocompleteProps } from '../../common/components/AccessoryGroupAutocomplete';
import { AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment$key } from './__generated__/AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment.graphql';
import { SalesRateResult } from '../../common/types/externalResult';

export const accessoryLineSubFormContext = createFormContext<{ sync: AccessoryLine }>();

//TODO: Add Line suffix
const fieldAccessoryIdKey = createFieldKey<string>();
export function useFieldAccessoryId(initialValue: string) {
  const [value] = useField(accessoryLineSubFormContext, fieldAccessoryIdKey, initialValue);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryIdKey);
  useMapper((v) => ({ id: v }), [], 'sync');

  return value;
}

export type FieldAccessoryType = ForwardAccessoryTypeAutocompleteProps<false, true>['value'];
const fieldAccessoryTypeKey = createFieldKey<FieldAccessoryType>();
export function useFieldAccessoryType(initialValue: FieldAccessoryType, disabled: boolean) {
  const [accessoryType, setAccessoryType] = useField(accessoryLineSubFormContext, fieldAccessoryTypeKey, initialValue);

  const accessoryTypeIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryTypeKey);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryTypeKey);
  useMapper((type, { isDirty }) => flagDirty({ accessoryType: type }, { accessoryType: isDirty }), [], 'sync');

  const renderAccessoryType = useCallback(
    (
      $key: AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key | null | undefined,
      saleKind: ServiceCallKind,
      gridMode: boolean,
    ) => (
      <InputAccessoryType
        $key={$key}
        saleKind={saleKind}
        value={accessoryType}
        setValue={setAccessoryType}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [accessoryType, disabled, setAccessoryType],
  );

  return { accessoryType, setAccessoryType, accessoryTypeIsDirty, renderAccessoryType };
}
function InputAccessoryType({
  $key,
  value,
  setValue,
  gridMode,
  saleKind,
  disabled,
}: {
  $key: AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key | null | undefined;
  value: FieldAccessoryType;
  setValue: SetValueFn<FieldAccessoryType>;
  gridMode: boolean;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryTypeKey);

  const handleChange = useCallback<NonNullable<ForwardAccessoryTypeAutocompleteProps<false, true>['onChange']>>(
    (v) => setValue(v),
    [setValue],
  );

  const $data = useFragment(
    graphql`
      fragment AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <AccessoryTypeAutocomplete
      disableClearable
      value={value}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
      onChange={handleChange}
      disabled={disabled}
      textFieldProps={() => ({
        label: gridMode ? '' : t('field.accessory.type'),
        className: gridMode ? 'borderless' : '',
        required: !gridMode,
        error: Object.keys(errors).length > 0,
      })}
    />
  );
}

export type FieldAccessoryLineSalesRateResult = SalesRateResult | null;

const fieldAccessoryLineSalesRateResultKey = createFieldKey<FieldAccessoryLineSalesRateResult>();
export function useFieldAccessoryLineSalesRateResult(initialValue: FieldAccessoryLineSalesRateResult | undefined) {
  const [accessoryLineSalesRateResult, setAccessoryLineSalesResultResult] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineSalesRateResultKey,
    initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineSalesRateResultKey);
  useMapper((v, { isDirty }) => flagDirty({ salesRateResult: v }, { salesRateResult: isDirty }), [], 'sync');
  const accessoryLineSalesRateResultIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryLineSalesRateResultKey);
  return { accessoryLineSalesRateResult, setAccessoryLineSalesResultResult, accessoryLineSalesRateResultIsDirty };
}

export type FieldAccessoryLineFractionAllowed = boolean;
const fieldAccessoryLineFractionAllowedKey = createFieldKey<FieldAccessoryLineFractionAllowed>();

export function useFieldAccessoryLineFractionAllowed(initialValue: boolean | undefined) {
  const [accessoryLineIsFractionAllowed, setAccessoryLineIsFractionAllowed] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineFractionAllowedKey,
    initialValue ?? false,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineFractionAllowedKey);
  useMapper((v) => ({ isFractionAllowed: v }), [], 'sync');

  return { accessoryLineIsFractionAllowed, setAccessoryLineIsFractionAllowed };
}

export type FieldAccessoryLineIsFixedQuantity = boolean;
const fieldAccessoryLineIsFixedQuantityKey = createFieldKey<FieldAccessoryLineIsFixedQuantity>();
export function useFieldAccessoryLineIsFixedQuantity(initialValue: boolean | undefined) {
  const [accessoryLineIsFixedQuantity, setAccessoryLineIsFixedQuantity] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineIsFixedQuantityKey,
    initialValue ?? false,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineIsFixedQuantityKey);
  useMapper((v) => ({ isFixedQuantity: v }), [], 'sync');

  return { accessoryLineIsFixedQuantity, setAccessoryLineIsFixedQuantity };
}

export type FieldAccessoryLineDefaultQuantity = number | null;
const fieldAccessoryLineDefaultQuantityKey = createFieldKey<FieldAccessoryLineDefaultQuantity>();
export function useFieldAccessoryLineDefaultQuantity(initialValue: number | null | undefined) {
  const [accessoryLineDefaultQuantity, setAccessoryLineDefaultQuantity] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineDefaultQuantityKey,
    initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineDefaultQuantityKey);
  useMapper((v) => ({ defaultQuantity: v }), [], 'sync');

  return { accessoryLineDefaultQuantity, setAccessoryLineDefaultQuantity };
}

export type FieldAccessoryGroup = ForwardAccessoryGroupAutocompleteProps['value'];
const fieldAccessoryGroupKey = createFieldKey<FieldAccessoryGroup>();
export function useFieldAccessoryGroup(
  initialValue: FieldAccessoryGroup,
  accessoryTypeCode: number | null,
  dispatchBranchId: DataID | null,
  disabled: boolean,
) {
  const [accessoryGroup, setAccessoryGroup] = useField(accessoryLineSubFormContext, fieldAccessoryGroupKey, initialValue ?? null);
  const accessoryGroupIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryGroupKey);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryGroupKey);
  useMapper((v, { isDirty }) => flagDirty({ accessoryGroup: v }, { accessoryGroup: isDirty }), [], 'sync');

  const renderAccessoryGroup = useCallback(
    (gridMode: boolean) => (
      <InputAccessoryGroup
        value={accessoryGroup}
        setValue={setAccessoryGroup}
        accessoryTypeCode={accessoryTypeCode}
        dispatchBranchId={dispatchBranchId}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [accessoryGroup, accessoryTypeCode, disabled, dispatchBranchId, setAccessoryGroup],
  );

  return {
    accessoryGroup: accessoryGroup,
    setAccessoryGroup: setAccessoryGroup,
    accessoryIsDirty: accessoryGroupIsDirty,
    renderAccessory: renderAccessoryGroup,
  };
}
function InputAccessoryGroup({
  value,
  setValue,
  accessoryTypeCode,
  dispatchBranchId,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryGroup;
  setValue: SetValueFn<FieldAccessoryGroup>;
  accessoryTypeCode: number | null;
  dispatchBranchId: DataID | null;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);

  const handleChange = useCallback<NonNullable<ForwardAccessoryGroupAutocompleteProps['onChange']>>((v) => setValue(v), [setValue]);

  return (
    <AccessoryGroupAutocomplete
      value={value}
      onChange={handleChange}
      disabled={disabled}
      textFieldProps={() => ({
        label: gridMode ? '' : t('field.accessory.accessory'),
        className: gridMode ? 'borderless' : '',
        required: !gridMode,
        error: Object.keys(errors).length > 0,
      })}
      accessoryTypeCode={accessoryTypeCode}
      dispatchBranchId={dispatchBranchId}
    />
  );
}

export type FieldAccessoryOutOfInventory = string;
const fieldAccessoryOutOfInventoryKey = createFieldKey<FieldAccessoryOutOfInventory>();
export function useFieldAccessoryOutOfInventory(initialValue: FieldAccessoryOutOfInventory, disabled: boolean) {
  const [accessoryOutOfInventory, setAccessoryOutOfInventory] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryOutOfInventoryKey,
    initialValue ?? '',
  );
  const accessoryOutOfInventoryIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryOutOfInventoryKey);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryOutOfInventoryKey);
  useMapper((v, { isDirty }) => flagDirty({ accessoryOutOfInventory: v }, { accessoryOutOfInventory: isDirty }), [], 'sync');

  const renderAccessoryOutOfInventory = useCallback(
    (gridMode: boolean) => (
      <AccessoryOutOfInventoryInput
        value={accessoryOutOfInventory}
        setValue={setAccessoryOutOfInventory}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [accessoryOutOfInventory, disabled, setAccessoryOutOfInventory],
  );

  return { accessoryOutOfInventory, setAccessoryOutOfInventory, accessoryOutOfInventoryIsDirty, renderAccessoryOutOfInventory };
}
function AccessoryOutOfInventoryInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryOutOfInventory;
  setValue: SetValueFn<FieldAccessoryOutOfInventory>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(({ target: { value: v } }) => setValue(v), [setValue]);

  return (
    <TextField
      value={value}
      onChange={handleChange}
      error={Object.keys(errors).length > 0}
      className={gridMode ? 'borderless' : ''}
      label={gridMode ? '' : t('field.accessory.accessory')}
      placeholder={t('placeholder.accessoryOutOfInventory')}
      disabled={disabled}
    />
  );
}

export type FieldRequireAccessory = boolean;
const fieldAccessoryLineRequireAccessoryKey = createFieldKey<FieldRequireAccessory>();

export function useFieldAccessoryGroupOrOutOfInventory(
  rules$key: AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment$key | null | undefined,
  initialRequireAccessory: FieldRequireAccessory | undefined,
  initialAccessory: FieldAccessoryGroup,
  initialOutOfInventory: FieldAccessoryOutOfInventory | null | undefined,
  accessoryTypeCode: number | null,
  dispatchBranchId: DataID | null,
  disabled: boolean,
) {
  const rules$data = useFragment(
    graphql`
      fragment AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment on AccessoryTypeLookup {
        isNumberMandatory
      }
    `,
    rules$key,
  );
  const isOutOfInventory = rules$data?.isNumberMandatory === false;

  const [requireAccessory, setRequireAccessory] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineRequireAccessoryKey,
    initialRequireAccessory ?? !isOutOfInventory,
  );
  const useMapperRequireAccessory = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineRequireAccessoryKey);
  useMapperRequireAccessory((v, { isDirty }) => flagDirty({ requireAccessory: v }, { requireAccessory: isDirty }), [], 'sync');

  const { accessoryGroup, setAccessoryGroup, renderAccessory } = useFieldAccessoryGroup(
    initialAccessory,
    accessoryTypeCode,
    dispatchBranchId,
    disabled,
  );
  const { accessoryOutOfInventory, setAccessoryOutOfInventory, renderAccessoryOutOfInventory } = useFieldAccessoryOutOfInventory(
    initialOutOfInventory ?? '',
    disabled,
  );

  const resetAccessoryGroupAndOutOfInventory = useCallback(
    (newRequireAccessory: boolean) => {
      if (disabled) {
        return;
      }

      if (requireAccessory !== newRequireAccessory) {
        setRequireAccessory(newRequireAccessory);
      }
      if (accessoryGroup != null) {
        setAccessoryGroup(null);
      }
      if (accessoryOutOfInventory !== '') {
        setAccessoryOutOfInventory('');
      }
    },
    [
      accessoryGroup,
      accessoryOutOfInventory,
      disabled,
      requireAccessory,
      setAccessoryGroup,
      setAccessoryOutOfInventory,
      setRequireAccessory,
    ],
  );

  const renderAccessoryOrOutOfInventory = useCallback(
    (gridMode: boolean) => (requireAccessory ? renderAccessory(gridMode) : renderAccessoryOutOfInventory(gridMode)),
    [requireAccessory, renderAccessory, renderAccessoryOutOfInventory],
  );

  return {
    accessoryGroup,
    setAccessoryGroup,
    accessoryOutOfInventory,
    setAccessoryOutOfInventory,
    resetAccessoryAndOutOfInventory: resetAccessoryGroupAndOutOfInventory,
    renderAccessoryOrOutOfInventory,
  };
}

export type FieldAccessoryBillingCode = ForwardAccessoryBillingCodeAutocompleteProps['value'];
const fieldAccessoryBillingCodeKey = createFieldKey<FieldAccessoryBillingCode>();
export function useFieldAccessoryBillingCode(initialValue: FieldAccessoryBillingCode, disabled: boolean) {
  const [accessoryBillingCode, setAccessoryBillingCode] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryBillingCodeKey,
    initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);
  useMapper((v, { isDirty }) => flagDirty({ billingCode: v }, { billingCode: isDirty }), [], 'sync');
  const accessoryBillingCodeIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);
  const renderAccessoryBillingCode = useCallback(
    (gridMode: boolean) => (
      <AccessoryBillingCodeInput value={accessoryBillingCode} setValue={setAccessoryBillingCode} gridMode={gridMode} disabled={disabled} />
    ),
    [accessoryBillingCode, disabled, setAccessoryBillingCode],
  );

  return { accessoryBillingCode, setAccessoryBillingCode, accessoryBillingCodeIsDirty, renderAccessoryBillingCode };
}
function AccessoryBillingCodeInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryBillingCode;
  setValue: SetValueFn<FieldAccessoryBillingCode>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);

  const handleChange = useCallback<NonNullable<ForwardAccessoryBillingCodeAutocompleteProps['onChange']>>((v) => setValue(v), [setValue]);

  return (
    <AccessoryBillingCodeAutocomplete
      value={value}
      onChange={handleChange}
      disabled={disabled}
      textFieldProps={() => ({
        label: gridMode ? '' : t('field.accessory.billingCode'),
        className: gridMode ? 'borderless' : '',
        required: !gridMode,
        error: Object.keys(errors).length > 0,
      })}
    />
  );
}

export type FieldAccessoryQuantity = NumberInputProps['value'];
const fieldAccessoryQuantityKey = createFieldKey<FieldAccessoryQuantity>();
export function useFieldAccessoryQuantity(initialValue: FieldAccessoryQuantity | undefined, disabled: boolean) {
  const [accessoryQuantity, setAccessoryQuantity] = useField(accessoryLineSubFormContext, fieldAccessoryQuantityKey, initialValue ?? null);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryQuantityKey);
  useMapper((v, { isDirty }) => flagDirty({ quantity: v }, { quantity: isDirty }), [], 'sync');

  const renderAccessoryQuantity = useCallback(
    (gridMode: boolean, startAdornment: ReactNode, isFractionAllowed: boolean, isFixedQuantity: boolean) => (
      <QuantityInput
        value={accessoryQuantity}
        setValue={setAccessoryQuantity}
        gridMode={gridMode}
        disabled={disabled || isFixedQuantity}
        startAdornment={startAdornment}
        fractionAllowed={isFractionAllowed}
      />
    ),
    [disabled, accessoryQuantity, setAccessoryQuantity],
  );

  return { accessoryQuantity, setAccessoryQuantity, renderAccessoryQuantity };
}
function QuantityInput({
  value,
  setValue,
  gridMode,
  disabled,
  startAdornment,
  fractionAllowed,
}: {
  value: FieldAccessoryQuantity;
  setValue: SetValueFn<FieldAccessoryQuantity>;
  gridMode: boolean;
  disabled: boolean;
  startAdornment: ReactNode;
  fractionAllowed: boolean;
}) {
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryQuantityKey);
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<NumberInputProps['onChange']>>((v) => setValue(v), [setValue]);

  return (
    <NumberInput
      value={value}
      onChange={handleChange}
      error={Object.keys(errors).length > 0}
      min={fractionAllowed ? 0 : 1}
      step={fractionAllowed ? 0.01 : 1}
      borderless={gridMode}
      label={gridMode ? '' : t('field.accessory.quantity')}
      InputProps={{
        inputProps: { style: { textAlign: 'right' } },
        startAdornment,
      }}
      disabled={disabled}
      required={!disabled}
    />
  );
}

export type FieldAccessoryRate = Price | null;
const fieldAccessoryRateKey = createFieldKey<FieldAccessoryRate>();
export function useFieldAccessoryRate(initialValue: FieldAccessoryRate | undefined, disabled: boolean) {
  const [accessoryRate, setAccessoryRate] = useField(accessoryLineSubFormContext, fieldAccessoryRateKey, initialValue);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryRateKey);
  useMapper((v, { isDirty }) => flagDirty({ rate: v }, { rate: isDirty }), [], 'sync');

  const renderAccessoryRate = useCallback(
    (gridMode: boolean, startAdornment: ReactNode) => (
      <RateInput
        value={accessoryRate}
        setValue={setAccessoryRate}
        gridMode={gridMode}
        disabled={disabled}
        startAdornment={startAdornment}
      />
    ),
    [accessoryRate, setAccessoryRate, disabled],
  );

  return { accessoryRate, setAccessoryRate, renderAccessoryRate };
}
function RateInput({
  disabled,
  value,
  gridMode,
  setValue,
  startAdornment,
}: {
  value: FieldAccessoryRate | undefined;
  setValue: SetValueFn<FieldAccessoryRate | undefined>;
  gridMode: boolean;
  disabled: boolean;
  startAdornment: ReactNode;
}) {
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryRateKey);
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<PriceInputProps['onChange']>>((v) => setValue(v), [setValue]);

  return (
    <PriceInput
      value={value}
      onChange={handleChange}
      error={Object.keys(errors).length > 0}
      label={gridMode ? '' : t('field.accessory.rate')}
      className={gridMode ? 'borderless' : undefined}
      InputProps={{
        inputProps: { style: { textAlign: 'right' } },
        startAdornment,
      }}
      disabled={disabled}
    />
  );
}

export type FieldAccessoryBillable = boolean | null;
const fieldAccessoryBillableKey = createFieldKey<FieldAccessoryBillable>();
export function useFieldAccessoryBillable(initialValue: FieldAccessoryBillable | undefined, disabled: boolean) {
  const [accessoryBillable, setValue] = useField(accessoryLineSubFormContext, fieldAccessoryBillableKey, initialValue ?? false);
  const setAccessoryBillable = useCallback<SetValueFn<boolean | null>>(
    (action) => {
      if (disabled) {
        return;
      }

      setValue(action);
    },
    [disabled, setValue],
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryBillableKey);
  useMapper((v, { isDirty }) => flagDirty({ billable: v }, { billable: isDirty }), [], 'sync');

  const renderAccessoryBillable = useCallback(
    (gridMode: boolean) => (
      <BillableInput value={accessoryBillable} setValue={setAccessoryBillable} gridMode={gridMode} disabled={disabled} />
    ),
    [accessoryBillable, disabled, setAccessoryBillable],
  );

  return { accessoryBillable, setAccessoryBillable, renderAccessoryBillable };
}
function BillableInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryBillable;
  setValue: SetValueFn<FieldAccessoryBillable>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<FormControlLabelProps['onChange']>>((_, v) => setValue(v), [setValue]);

  return gridMode ? (
    <Checkbox checked={value ?? false} onChange={handleChange} disabled={disabled} />
  ) : (
    <FormControlLabel
      checked={value ?? false}
      onChange={handleChange}
      control={<Checkbox />}
      label={t('field.accessory.billable')}
      disabled={disabled}
    />
  );
}

export type AccessoryLineAutomatic = boolean;
const fieldAccessoryLineAutomaticKey = createFieldKey<AccessoryLineAutomatic>();

export function useFieldAccessoryLineAutomatic(initialValue: boolean | undefined) {
  const [automatic, setAutomatic] = useField(accessoryLineSubFormContext, fieldAccessoryLineAutomaticKey, initialValue ?? false);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineAutomaticKey);
  useMapper((v) => ({ automatic: v }), [], 'sync');

  return { automatic, setAutomatic };
}
